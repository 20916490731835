import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back Squat 4×4\\@85% 1RM`}</p>
    <p>{`Glute Ham Raise 4×4`}</p>
    <p>{`then,`}</p>
    <p>{`1 Lap Run`}</p>
    <p>{`100Ft OH Walking Lunges (45/25`}{`#`}{`)`}</p>
    <p>{`80 Situps`}</p>
    <p>{`60 Step Ups (total, 24/20″)`}</p>
    <p>{`40 Calorie Row`}</p>
    <p>{`20 Pistols (total)`}</p>
    <p>{`1 Lap Run`}</p>
    <p>{`for time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      